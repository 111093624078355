import React from "react";
import MainNav from "../../components/MainNav/MainNav";
import MainFooter from "../../components/MainFooter/MainFooter";
import HomeHeader from "./HomeHeader/HomeHeader";
import HomeCategories from "./HomeCategories/HomeCategories";
import HomeCreatorsList from "./HomeCreatorsList";

export default function HomePage() {
  return (
    <>
      <MainNav />
      <HomeHeader />
      <HomeCategories />
      <HomeCreatorsList />
      <MainFooter />
    </>
  );
}
