import styled from "styled-components"

/**
 * General Components
 */
const TableCell = styled.div`
  align-items: center;
  display: flex;
`

export const TRow = styled.div`
  cursor: pointer;
  display: grid;
  margin: 4px 0;
  grid-template-columns: 75px 1fr 120px 75px;
  grid-template-areas: "Icon Creator Location Subscribers";

  &:nth-child(odd) {
    background-color: #F9F9F9;
  }

  &:hover {
    background-color: #F4F4F4;
    border-radius: 5px;
  }
  
  @media only screen and (min-width: 768px) {
    grid-template-columns: 75px 1fr 120px 75px repeat(2, 100px);
    grid-template-areas: "Icon Creator Location Videos Subscribers Views";
  }
  @media only screen and (min-width: 1024px) {
    grid-template-columns: 75px 1fr 120px repeat(3, 75px) 1fr 200px;
    grid-template-areas: "Icon Creator Location Videos Subscribers Views Tags Links";
  }
 
`

export const THeadRow = styled(TRow)`
  background-color: #F4F4F4 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 0;
  /* border: 2px solid green; */
`

/**
 * Table Cells - Rows
 */
export const TIcon = styled(TableCell)`
  overflow: hidden;
  grid-area: Icon;
  img {
    border-radius: 50%;
    display: block;
    height: 50px;
    width: 50px;
  }
  
  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const TCreator = styled(TableCell)`
  grid-area: Creator;

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const TLocation = styled(TableCell)`
  grid-area: Location;

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const TVideos = styled(TableCell)`
  display: none;
  grid-area: Videos;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
  @media only screen and (min-width: 1024px) {

  }
`

export const TSubscribers = styled(TableCell)`
  grid-area: Subscribers;

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const TViews = styled(TableCell)`
  display: none;
  grid-area: Views;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
  @media only screen and (min-width: 1024px) {

  }
`

export const TTags = styled(TableCell)`
  display: none;
  grid-area: Tags;

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {
    display: flex;

  }
`

export const TLinks = styled(TableCell)`
  display: none;
  grid-area: Links;

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {
    display: flex;

  }
`

/*
 * Table Cells - Header
 */
export const THIcon = styled(TIcon)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THCreator = styled(TCreator)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THLocation = styled(TLocation)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THVideos = styled(TVideos)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THSubscribers = styled(TSubscribers)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THViews = styled(TViews)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THTags = styled(TTags)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`

export const THLinks = styled(TLinks)`

  @media only screen and (min-width: 768px) {

  }
  @media only screen and (min-width: 1024px) {

  }
`
