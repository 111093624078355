import MainFooter from "../../components/MainFooter/MainFooter";
import MainNav from "../../components/MainNav/MainNav";
import React from "react";
import { MOZART_CATEGORIES } from "data/MOZART_CATEGORIES";
import CategoryPreview from "./CategoryPreview";

export default function CategoriesPage() {
  return (
    <div>
      <MainNav />
      <div className="mx-auto container">
        <section id="body" className="my-8">
          <header
            id="categories-header"
            className="border-t px-2 py-4 border-black"
          >
            <h2 className="uppercase font-mozartBrand text-center md:text-left text-3xl">
              Explore categories
            </h2>
          </header>
          <section
            id="categories-list"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
          >
            {MOZART_CATEGORIES.map((category) => (
              <CategoryPreview
                key={category.name}
                name={category.name}
                route={category.route}
                image={category.image}
              />
            ))}
          </section>
        </section>
      </div>
      <MainFooter />
    </div>
  );
}
