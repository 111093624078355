import React from "react";
import MainFooter from "../../components/MainFooter/MainFooter";
import MainNav from "../../components/MainNav/MainNav";
import CreatorsList from "components/CreatorsList/CreatorsList";
import { channelsData } from "assets/data/channelsData";

export default function AllCreatorsPage() {
  return (
    <>
      <MainNav />
      <div className="py-6">
        <h2 className="font-mozartBrand text-5xl text-center uppercase">
          All creators
        </h2>
        <CreatorsList creators={channelsData} />
      </div>
      <MainFooter />
    </>
  );
}
