import React from "react";
import { MOZART_CATEGORIES } from "data/MOZART_CATEGORIES";
import CategoryPreview from "../CategoryPreview/CategoryPreview";

export default function HomeCategories() {
  return (
    <section className="">
      <div className="container mx-auto">
        <section className="pt-10 px-2">
          <h2 className="font-mozartBrand text-3xl uppercase border-t border-black py-2 mb-10">
            Explore categories
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2.5 mb-10">
            {MOZART_CATEGORIES.map((category) => (
              <CategoryPreview
                key={category.name}
                name={category.name}
                image={category.image}
                route={category.route}
              />
            ))}
          </div>
        </section>
        {/* <SelectedCategory /> */}
      </div>
    </section>
  );
}
