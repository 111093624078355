import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useWindowWidth } from "helpers/useWindowWidth";
import searchIcon from "assets/icons/icon-search.svg";
import mozartLogo from "../../assets/mozart-logos/50w/Transparent-White.png";

export default function MainNav() {
  const [searchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  const width = useWindowWidth();
  console.log("Width: ", width);

  if (width > 768 && searchActive) {
    setSearchActive(false);
  }

  useEffect(() => {
    if (searchActive) inputRef.current.focus();
  }, [searchActive]);

  function activateSearch() {
    // @ts-ignore
    inputRef.current.focus();
    if (window.innerWidth > 768) return;
    setSearchActive((v) => !v);
  }

  // Styles
  var baseStylesInput;
  baseStylesInput = "p-2 px-4 rounded-full w-[300px]";
  baseStylesInput = `${baseStylesInput} ${searchActive ? "block" : "hidden"}`;
  const stylesInput = `${baseStylesInput} md:block`;

  return (
    <div id="section-home" className="bg-black py-2">
      <div className="container mx-auto">
        <div id="content" className="flex items-center justify-between">
          {!searchActive ? (
            <button className="mr-4">
              <img src={mozartLogo} alt="Mozart" />
            </button>
          ) : null}
          <div className="p-1 flex items-center">
            <button className="mr-4" onClick={activateSearch}>
              <img src={searchIcon} alt="Search" className="block" />
            </button>
            <input
              ref={inputRef}
              type="text"
              placeholder="Search Mozart"
              className={stylesInput}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
