import appsIcon from "assets/images/categories/category-apps.jpg"
import coversIcon from "assets/images/categories/category-covers.jpg"
import educationIcon from "assets/images/categories/category-education.jpg"
import entertainmentIcon from "assets/images/categories/category-entertainment.jpg"
import midiIcon from "assets/images/categories/category-midi.png"
import musicIcon from "assets/images/categories/category-music.jpg"
import performanceIcon from "assets/images/categories/category-performance.jpg"
import pianosIcon from "assets/images/categories/category-pianos.jpg"
import retailersIcon from "assets/images/categories/category-retailers.jpg"
import reviewsIcon from "assets/images/categories/category-reviews.jpg"
import sheetsIcon from "assets/images/categories/category-sheets.jpg"
import tutorialIcon from "assets/images/categories/category-tutorial.jpg"

export const MOZART_CATEGORIES = [
  {
    key: "software",
    name: "Software apps",
    route: "/category/software",
    image: appsIcon
  },
  {
    key: "covers",
    name: "Covers",
    route: "/category/covers",
    image: coversIcon
  },
  {
    key: "education",
    name: "Education",
    route: "/category/education",
    image: educationIcon
  },
  {
    key: "entertainment",
    name: "Entertainment",
    route: "/category/entertainment",
    image: entertainmentIcon
  },
  {
    key: "pianos",
    name: "Pianos",
    route: "/category/pianos",
    image: midiIcon
  },
  {
    key: "midi",
    name: "MIDI",
    route: "/category/midi",
    image: musicIcon
  },
  {
    key: "music",
    name: "Music videos",
    route: "/category/music",
    image: performanceIcon
  },
  {
    key: "performances",
    name: "Performances",
    route: "/category/performances",
    image: pianosIcon
  },
  {
    key: "retailers",
    name: "Retailers",
    route: "/category/retailers",
    image: retailersIcon
  },
  {
    key: "reviews",
    name: "Reviews",
    route: "/category/reviews",
    image: reviewsIcon
  },
  {
    key: "sheets",
    name: "Sheet music",
    route: "/category/sheets",
    image: sheetsIcon
  },
  {
    key: "tutorials",
    name: "Tutorials",
    route: "/category/tutorials",
    image: tutorialIcon
  },
]

export const CATEGORIES_HASH = {}
MOZART_CATEGORIES.forEach(v => {
  CATEGORIES_HASH[v.key] = v
})