import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "pages/HomePage/HomePage";
import CategoriesPage from "pages/CategoriesPage/CategoriesPage";
import CategoryPage from "pages/CategoryPage/CategoryPage";
import AllCreatorsPage from "pages/AllCreatorsPage/AllCreatorsPage";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import CreatorPage from "pages/CreatorPage/CreatorPage";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/categories",
    element: <CategoriesPage />,
  },
  // {
  //   path: "/category/:categoryName",
  //   element: <CategoryPage />
  // },
  {
    path: "/creators",
    element: <AllCreatorsPage />,
  },
  // {
  //   path: "/creator/:creatorId",
  //   element: <CreatorPage />
  // },
]);

export default function AppRouter() {
  return <RouterProvider router={appRouter} />;
}
