import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OPACITY_DARKNESS = "0.4";

// const PreviewContainer = styled(Link)`
const PreviewContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0 , ${OPACITY_DARKNESS}), rgba(0, 0, 0 ,${OPACITY_DARKNESS})), url(${(props) => props.image});
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  display: flex;
  color: white;
  height: 180px;
  justify-content: center;
  margin: 5px;
  transition: background-color 2s;
  
  &:hover {
    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${(props) => props.image});
    color: #F4F4F4;
  }

  @media only screen and (min-width: 768px) {
    justify-content: left;
  }
`;

export default function CategoryPreview(props) {
  const { name, route, image } = props;
  return (
    <PreviewContainer to={route} image={image}>
      <h3 className="block ml-4 uppercase font-mozartBrand text-center text-4xl">
        {name}
      </h3>
    </PreviewContainer>
  );
}
