import React from "react";
import { useState } from "react";
import CreatorsGridView from "./CreatorsGridView/CreatorsGridView";
import CreatorsSearchNav from "./CreatorsSearchNav/CreatorsSearchNav";
import CreatorsTableView from "./CreatorsTableView/CreatorsTableView";

export default function CreatorsList(props) {
  const [tableView, setTableView] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { creators } = props;

  return (
    <div className="py-6">
      {/* <CreatorsSearchNav
        searchText={searchText}
        setSearchText={setSearchText}
        setTableView={setTableView}
      /> */}
      {!tableView ? (
        <CreatorsGridView creators={creators} />
      ) : (
        <CreatorsTableView creators={creators} />
      )}
    </div>
  );
}
