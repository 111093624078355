import React from "react";
import { TableHeaderRow, TableRow } from "./TableRow";

export default function CreatorsTableView(props) {
  const { creators } = props;
  return (
    <section>
      <div className="container mx-auto">
        <div className="">
          <TableHeaderRow />
          {creators.map((creator) => (
            <TableRow creator={creator} />
          ))}
        </div>
      </div>
    </section>
  );
}
