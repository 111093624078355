import andrewHuang from "assets/images/featured/andrew-huang.jpg"
import brendanKavanaugh from "assets/images/featured/brendan-kavanaugh.jpg"
import danielThrasher from "assets/images/featured/daniel-thrasher.jpg"
import jacobCollier from "assets/images/featured/jacob-collier.jpg"
import peterBuka from "assets/images/featured/peter-buka.jpg"
import peterPlutax from "assets/images/featured/peter-plutax.jpg"
import pianoGuys from "assets/images/featured/pianoguys.jpg"
import postmodernJukebox from "assets/images/featured/postmodern-jukebox.jpg"
import rousseau from "assets/images/featured/rousseau.jpg"
import theTheorist from "assets/images/featured/the-theorist.jpg"
import tiffanyPoon from "assets/images/featured/tiffany-poon.jpg"
import vinheteiro from "assets/images/featured/vinheteiro.jpg"

export const imageTopLeft = {
  alt: "imageTopLeft",
  image: andrewHuang,
  route: "/creator/9xc9ads4919cd",
};

export const imageTopCenterLeft = {
  alt: "imageTopCenterLeft",
  image: brendanKavanaugh,
  route: "/creator/9xc9ads4919cd",
};

export const imageTopCenterRight = {
  alt: "imageTopCenterRight",
  image: danielThrasher,
  route: "/creator/9xc9ads4919cd",
};

export const imageTopRight = {
  alt: "imageTopRight",
  image: jacobCollier,
  route: "/creator/9xc9ads4919cd",
};

export const imageCenterTopLeft = {
  alt: "imageCenterTopLeft",
  image: peterBuka,
  route: "/creator/9xc9ads4919cd",
};

export const imageCenterTopRight = {
  alt: "imageCenterTopRight",
  image: peterPlutax,
  route: "/creator/9xc9ads4919cd",
};

export const imageCenterBottomLeft = {
  alt: "imageCenterBottomLeft",
  image: pianoGuys,
  route: "/creator/9xc9ads4919cd",
};

export const imageCenterBottomRight = {
  alt: "imageCenterBottomRight",
  image: postmodernJukebox,
  route: "/creator/9xc9ads4919cd",
};

export const imageBottomLeft = {
  alt: "imageBottomLeft",
  image: rousseau,
  route: "/creator/9xc9ads4919cd",
};

export const imageBottomCenterLeft = {
  alt: "imageBottomCenterLeft",
  image: theTheorist,
  route: "/creator/9xc9ads4919cd",
};

export const imageBottomCenterRight = {
  alt: "imageBottomCenterRight",
  image: tiffanyPoon,
  route: "/creator/9xc9ads4919cd",
};

export const imageBottomRight = {
  alt: "imageBottomRight",
  image: vinheteiro,
  route: "/creator/9xc9ads4919cd",
};
