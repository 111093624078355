import React from "react";
import CreatorsList from "components/CreatorsList/CreatorsList";
import HomeFeaturedCreatorBanner from "./HomeFeaturedCreatorBanner/HomeFeaturedCreatorBanner";
import HomeFeaturedCreators from "./HomeFeaturedCreators";
import { channelsData } from "assets/data/channelsData";

export default function HomeCreatorsList() {
  const creators = channelsData.slice(0, 50);

  return (
    <div className="p-3">
      <h2 className="font-mozartBrand text-5xl text-center uppercase">
        All creators
      </h2>
      <CreatorsList creators={creators} />
      {/* <HomeFeaturedCreatorBanner /> */}
      {/* <HomeFeaturedCreators /> */}
      {/* <CreatorsList /> */}
    </div>
  );
}
