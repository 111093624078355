import React from "react";
import { PreviewContainer } from "./CategoryPreviewStyles";

export default function CategoryPreview(props) {
  const { name, image, route } = props;

  return (
    <PreviewContainer to={route} image={image} key={name}>
      <h3 className="uppercase font-mozartBrand text-2xl">{name}</h3>
    </PreviewContainer>
  );
}
