import { tempImage } from "components/tempImage";
import React from "react";
import {
  THCreator,
  THeadRow,
  THIcon,
  THLinks,
  THLocation,
  THSubscribers,
  THTags,
  THVideos,
  THViews,
  TCreator,
  TIcon,
  TLinks,
  TLocation,
  TRow,
  TSubscribers,
  TTags,
  TVideos,
  TViews,
} from "./CreatorsTableViewStyles";
import facebookIcon from "assets/icons/icon-facebook.png";
import instagramIcon from "assets/icons/icon-instagram.png";
import youtubeIcon from "assets/icons/icon-youtube.png";
import { Link } from "react-router-dom";
import { numFormatter } from "helpers/numFormatter";
import { countryCodesMap } from "helpers/countryCodes";
import { useWindowWidth } from "helpers/useWindowWidth";

const NAME_MAX_LENGTH = 18

export const TableHeaderRow = () => (
  <THeadRow>
    <THIcon />
    <THCreator>Creator</THCreator>
    <THLocation>Location</THLocation>
    <THVideos>Videos</THVideos>
    <THSubscribers>Subs</THSubscribers>
    <THViews>Views</THViews>
    <THTags>Tags</THTags>
    <THLinks>Links</THLinks>
  </THeadRow>
);

const LinkItem = ({ icon, url, name }) => {
  return (
    <a href={url} className="w-[25px] mr-2" target="_blank" rel="noreferrer">
      <img src={icon} alt={name} className="w-[25px]" />
    </a>
  );
};

const tags = [
  {
    name: "Covers",
    bgColor: "#F2DC5D",
    textColor: "#000",
    url: ""
  },
  {
    name: "Software",
    bgColor: "#542344",
    textColor: "#FFF",
    url: ""
  },
  {
    name: "Education",
    bgColor: "#EF767A",
    textColor: "#FFFFFF",
    url: ""
  },
  {
    name: "MIDI",
    bgColor: "#361D2E",
    textColor: "#FFFFFF",
    url: ""
  }
]

function TagItem ({tag}) {
  const { name, bgColor, textColor, url} = tag 
  // const tagRoute = url
  const tagRoute = `/tag/${name.toLowerCase()}`
  const baseClasses = "block px-3 py-1 text-sm rounded-full mr-1"
  const classes = `${baseClasses} bg-[${bgColor}] text-[${textColor}]`
  const styles = {
    backgroundColor: bgColor,
    color: textColor
  }

  return (
    <Link to={tagRoute} className={classes} style={styles}>{name}</Link>
  )
}

export const TableRow = (props) => {
  const width = useWindowWidth()
  
  const { creator } = props;
  const { name, location, videos, subscribers, views } = creator;
  
  var nameRoute;
  nameRoute = name.replaceAll(" ", "-");
  nameRoute = nameRoute.toLowerCase();
  const creatorRoute = `/creator/${nameRoute}`;
  const creatorId = creator["Channel ID"];
  const creatorIcon = require(`assets/thumbnails/${creatorId}.jpg`);

  var formattedName
  if(width < 1300) {
    formattedName = name.length > NAME_MAX_LENGTH ? `${name.slice(0, NAME_MAX_LENGTH)}...` : name;
  }
  else {
    formattedName = name
  }

  const formattedVideos = videos;
  const formattedSubs = numFormatter(subscribers);
  const formattedViews = numFormatter(views);
  const formattedLocation = countryCodesMap[creator.country];

  return (
    <TRow>
      <TIcon>
        <img
          // src={creator.thumbnail}
          src={creatorIcon}
          alt="Creator Icon"
          className="block w-full h-full object-cover overflow-hidden rounded"
        />
      </TIcon>
      <TCreator>
        <Link to={creatorRoute} className="text-blue-700 hover:text-blue-900">
          {formattedName}
        </Link>
      </TCreator>
      {/* <TLocation>{location}</TLocation> */}
      <TLocation>{formattedLocation}</TLocation>
      <TVideos>{formattedVideos}</TVideos>
      <TSubscribers>{formattedSubs}</TSubscribers>
      <TViews>{formattedViews}</TViews>
      <TTags>
        {tags.map(tag => <TagItem tag={tag} />)}
      </TTags>
      <TLinks>
        {creator.facebook ? (
          <LinkItem
            icon={facebookIcon}
            name="Facebook"
            url={creator.facebook}
          />
        ) : null}
        {creator.instagram ? (
          <LinkItem
            icon={instagramIcon}
            name="Instagram"
            url={creator.instagram}
          />
        ) : null}
        <LinkItem icon={youtubeIcon} name="YouTube" url={creator.youtube} />
      </TLinks>
    </TRow>
  );
};
