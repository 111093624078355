import React from "react";
import { imageBottomCenterLeft, imageBottomCenterRight, imageBottomLeft, imageBottomRight, imageCenterBottomLeft, imageCenterBottomRight, imageCenterTopLeft, imageCenterTopRight, imageTopCenterLeft, imageTopCenterRight, imageTopLeft, imageTopRight } from "./HomeHeaderImages";
import {
  BottomCenterLeft,
  BottomCenterRight,
  BottomLeft,
  BottomRight,
  CenterBottomLeft,
  CenterBottomRight,
  CenterContent,
  CenterTopLeft,
  CenterTopRight,
  HomeContainer,
  TopCenterLeft,
  TopCenterRight,
  TopLeft,
  TopRight,
} from "./HomeHeaderStyles";

export default function HomeHeader() {
  return (
    <HomeContainer>
      <TopLeft to={imageTopLeft.route}>
        <img 
          src={imageTopLeft.image} 
          alt={imageTopLeft.alt} 
        />
      </TopLeft>
      <TopCenterLeft to={imageTopCenterLeft.route}>
        <img 
          src={imageTopCenterLeft.image} 
          alt={imageTopCenterLeft.alt} 
        />
      </TopCenterLeft>
      <TopCenterRight to={imageTopCenterRight.route}>
        <img 
          src={imageTopCenterRight.image} 
          alt={imageTopCenterRight.alt} 
        />
      </TopCenterRight>
      <TopRight to={imageTopRight.route}>
        <img 
          src={imageTopRight.image} 
          alt={imageTopRight.alt} 
        />
      </TopRight>
      <CenterTopLeft to={imageCenterTopLeft.route}>
        <img 
          src={imageCenterTopLeft.image} 
          alt={imageCenterTopLeft.alt} 
        />
      </CenterTopLeft>
      <CenterTopRight to={imageCenterTopRight.route}>
        <img 
          src={imageCenterTopRight.image} 
          alt={imageCenterTopRight.alt} 
        />
      </CenterTopRight>
      <CenterBottomLeft to={imageCenterBottomLeft.route}>
        <img
          src={imageCenterBottomLeft.image}
          alt={imageCenterBottomLeft.alt}
        />
      </CenterBottomLeft>
      <CenterBottomRight to={imageCenterBottomRight.route}>
        <img
          src={imageCenterBottomRight.image}
          alt={imageCenterBottomRight.alt}
        />
      </CenterBottomRight>
      <BottomLeft to={imageBottomLeft.route}>
        <img 
          src={imageBottomLeft.image} 
          alt={imageBottomLeft.alt} 
        />
      </BottomLeft>
      <BottomCenterLeft to={imageBottomCenterLeft.route}>
        <img
          src={imageBottomCenterLeft.image}
          alt={imageBottomCenterLeft.alt}
        />
      </BottomCenterLeft>
      <BottomCenterRight to={imageBottomCenterRight.route}>
        <img
          src={imageBottomCenterRight.image}
          alt={imageBottomCenterRight.alt}
        />
      </BottomCenterRight>
      <BottomRight to={imageBottomRight.route}>
        <img 
          src={imageBottomRight.image} 
          alt={imageBottomRight.alt} 
        />
      </BottomRight>
      <CenterContent>
        <h1 className="font-mozartBrand text-6xl md:text-8xl uppercase mb-6">
          Mozart <br className="hidden md:block" /> 400
        </h1>
        <p className="text-md md:text-xl w-2/3">
          The piano central platform for the top piano content creators around
          the world
        </p>
      </CenterContent>
    </HomeContainer>
  );
}
