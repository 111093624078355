import { Link } from "react-router-dom";
import styled from "styled-components";

const OPACITY_DARKNESS = "0.4";

// export const PreviewContainer = styled(Link)`
export const PreviewContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(
      rgba(0, 0, 0, ${OPACITY_DARKNESS}),
      rgba(0, 0, 0, ${OPACITY_DARKNESS})
    ),
    url(${(props) => props.image});
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  color: white;
  display: flex;
  height: 100px;
  justify-content: center;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${(props) => props.image});
  }
`;
