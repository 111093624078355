import styled from "styled-components"

export const FeaturedCreatorContainer = styled.div`
  background-color: orange;
  display: grid;
  border: 2px solid blue;
  height: 600px;
  
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 200px);
  grid-template-areas: 
    "FeaturedLeft FeaturedTopRight"
    "FeaturedLeft FeaturedContent"
    "FeaturedLeft FeaturedBottomRight";

  @media only screen and (min-width: 1024px) {
    display: none;
  }  
`

export const FeaturedLeft = styled.div`
  grid-area: FeaturedLeft;
  border: 5px solid blue;
  height: 100%;
  width: 100%;
`

export const FeaturedTopRight = styled.div`
  grid-area: FeaturedTopRight;
`

export const FeaturedContent = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-area: FeaturedContent;
  padding: 20px;
  text-align: center;
`
export const FeaturedBottomRight = styled.div`
  grid-area: FeaturedBottomRight;

`