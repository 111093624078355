import React from "react";
import { Link } from "react-router-dom";
import mozartLogo from "../../assets/mozart-logos/50w/Transparent-White.png";

export default function MainFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="py-6 pb-10 mt-2 bg-black text-white">
      <div className="container mx-auto">
        <div className="flex items-center flex-col md:flex-row">
          <Link to="/" className="mb-4">
            <img className="px-4" src={mozartLogo} alt="Mozart" />
          </Link>
          <div className="text-center mb-4 md:mb-0 md:mr-6">
            <Link className="hover:underline mx-4" to="/categories">
              Categories
            </Link>
            <Link className="hover:underline mx-4" to="/creators">
              Creators
            </Link>
          </div>
          <p className="text-white text-center">
            Mozart Piano Inc., Copyright {currentYear}, All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}
