import { Link } from "react-router-dom";
import styled from "styled-components";

export const HomeContainer = styled.section`
  display: grid;
  position: relative;
  margin: 10px 0;
  grid-template-rows: repeat(3, 75px) auto repeat(3, 75px);
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-template-areas:
    "TopLeft TopRight"
    "TopCenterLeft TopCenterRight"
    "CenterTopLeft CenterTopRight"
    "CenterContent CenterContent"
    "CenterBottomLeft CenterBottomRight"
    "BottomCenterLeft BottomCenterRight"
    "BottomLeft BottomRight";
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    padding: 30px 0;
    margin: 60px 0;
    grid-template-columns: 1.25fr 1fr 1fr 1.25fr;
    grid-template-rows: 210px 150px 150px 210px;
    grid-template-areas:
      "TopLeft TopCenterLeft TopCenterRight TopRight"
      "CenterTopLeft CenterContent CenterContent CenterTopRight"
      "CenterBottomLeft CenterContent CenterContent CenterBottomRight"
      "BottomLeft BottomCenterLeft BottomCenterRight BottomRight";
  }

  @media only screen and (min-width: 1024px) {
  }
`;

// const GridItem = styled(Link)`
const GridItem = styled.div`
  border-radius: 10px;
  display: block;
  position: relative;
  overflow: hidden;

  img {
    border-radius: 10px;
    display: block;
    object-fit: cover;
    height: 75px;
    position: relative;
    width: 100%;

    &:after {
      background-color: black;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 10;
      content: "";
      position: absolute;
    }
  }

  @media only screen and (min-width: 768px) {
    img {
      height: 150px;
    }
  }

  @media only screen and (min-width: 1024px) {
    img {
      max-width: 360px;
    }
  }
`;

// Top
export const TopLeft = styled(GridItem)`
  grid-area: TopLeft;
  right: 30px;

  @media only screen and (min-width: 768px) {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    right: 0;
  }
  @media only screen and (min-width: 1024px) {
  }
`;

export const TopCenterLeft = styled(GridItem)`
  grid-area: TopCenterLeft;
  left: 30px;
  
  img {
    object-position: center 25%;
  }

  @media only screen and (min-width: 768px) {
    align-items: flex-end;
    display: flex;
    left: 0;
    position: relative;

    img {
      bottom: 60px;
      position: absolute;
      max-width: 425px;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const TopCenterRight = styled(GridItem)`
  grid-area: TopCenterRight;
  left: 30px;

  @media only screen and (min-width: 768px) {
    align-items: flex-end;
    display: flex;
    position: relative;
    left: 0;

    img {
      bottom: 60px;
      position: absolute;
      max-width: 425px;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const TopRight = styled(GridItem)`
  grid-area: TopRight;
  right: 30px;

  img {
    object-position: center 25%;
  }

  @media only screen and (min-width: 768px) {
    align-items: flex-end;
    display: flex;
    right: 0;
  }

  @media only screen and (min-width: 1024px) {
  }
`;

// Center
export const CenterTopLeft = styled(GridItem)`
  grid-area: CenterTopLeft;
  right: 30px;

  img {
    object-position: center 20%;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 0;

    img {
      position: absolute;
      right: 60px;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const CenterTopRight = styled(GridItem)`
  grid-area: CenterTopRight;
  right: 30px;

  @media only screen and (min-width: 768px) {
    position: relative;
    right: 0;

    img {
      left: 60px;
      position: absolute;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const CenterBottomLeft = styled(GridItem)`
  grid-area: CenterBottomLeft;
  left: 30px;

  img {
    object-position: center 25%;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    left: 0;
    position: relative;

    img {
      position: absolute;
      object-position: center 40%;
      right: 60px;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const CenterBottomRight = styled(GridItem)`
  grid-area: CenterBottomRight;
  left: 30px;

  img {
    object-position: center 25%;
  }

  @media only screen and (min-width: 768px) {
    left: 0;
    position: relative;

    img {
      left: 60px;
      position: absolute;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

// Bottom
export const BottomLeft = styled(GridItem)`
  grid-area: BottomLeft;
  left: 30px;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    left: 0;
  }
  @media only screen and (min-width: 1024px) {
  }
`;

export const BottomCenterLeft = styled(GridItem)`
  grid-area: BottomCenterLeft;
  right: 30px;

  img {
    object-position: center 25%;
  }

  @media only screen and (min-width: 768px) {
    right: 0;

    img {
      position: absolute;
      max-width: 425px;
      top: 60px;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const BottomCenterRight = styled(GridItem)`
  grid-area: BottomCenterRight;
  right: 30px;

  @media only screen and (min-width: 768px) {
    right: 0;

    img {
      position: absolute;
      max-width: 425px;
      top: 60px;
    }
  }

  @media only screen and (min-width: 1024px) {
  }
`;

export const BottomRight = styled(GridItem)`
  grid-area: BottomRight;
  left: 30px;
  img {
    object-position: center 25%;
  }

  @media only screen and (min-width: 768px) {
    left: 0;
  }
  @media only screen and (min-width: 1024px) {
  }
`;

// Content
export const CenterContent = styled.div`
  align-items: Center;
  background-color: white;
  display: flex;
  flex-direction: column;
  grid-area: CenterContent;
  justify-content: Center;
  padding: 40px 0;
  text-align: Center;

  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
  @media only screen and (min-width: 1024px) {
  }
`;
